<template>

    <section class="landing-section">
        <div class="landing-section_hero-diamond" :style="{ backgroundImage: 'url(' + background + ')'}">
            <div class="grid-container-home">
                <div class="texAndLogo_content">

                    <div class="textAndLogo__content-left">
                        <h2 class="textAndLogo_block-text__title">The team</h2>
                        <transition appear
                                    @before-enter="beforeEnter"
                                    @enter="enter"
                                    @after-enter="afterEnter">
                            <p class="textAndLogo__bottom-text">coffee, friendship and hard work</p>
                        </transition>
                    </div>

                    <div class="textAndLogo__content-right">
                        <img :src="logo" alt="Scriptics Decisions" class="floating-image">
                        <img :src="logo" alt="Scriptics Decisions" class="static-image">
                    </div>

                </div>
            </div>
        </div>

        <!--    <img id="triangles-hero-compound" :src="hero" alt="Hero">-->
        <!--    <img id="triangles-hero-1" :src="hero1" alt="Hero1">-->
        <!--    <img id="triangles-hero-duo" :src="heroDuo" alt="HeroDuo">-->
        <!--    <img id="trianglesHero3" :src="hero3" alt="Hero3">-->

        <TeamSlider></TeamSlider>
        <!--    <div class="team-portraits">-->
        <!--      <div v-for="(employee, index) in allEmployees" :key="index" class="individual">-->
        <!--        <img :src="`${link}${employee.over_picture}`" alt="employee">-->
        <!--      </div>-->
        <!--    </div>-->
        <ApplyNow></ApplyNow>
        <Footer color="#181818">
            <DropDownFooter colorMobile="#181818">
                <DropDown subMenuColor="#181818"></DropDown>
            </DropDownFooter>
        </Footer>
    </section>

</template>


<script>
import hero from '@/assets/hero-diamond-flume.webp';
import TeamSlider from "../components/Team/TeamSlider";
import DropDownFooter from "@/components/FooterMobile/DropDownFooter";
import DropDown from "@/components/FooterMobile/DropDown";
import ApplyNow from "@/components/Team/ApplyNow";
import Footer from "../components/Home/Footer";
import Scriptics from '@/assets/scriptics-s20.svg';
import gsap from 'gsap';
import {mapActions, mapGetters} from "vuex";
import {CMS} from "@/main";

export default {
    name: 'Team',
    components: {DropDown, DropDownFooter, ApplyNow, TeamSlider, Footer},
    computed: mapGetters(["allEmployees"]),

    data() {
        return {
            link: CMS,
            company: 'Scriptics',
            messageCompany: 'A creative company like no other',
            background: hero,
            logo: Scriptics,
        }
    },
    methods: {
        ...mapActions(['loadEmployees']),

        beforeEnter: function (el) {
            el.style.transform = 'translateY(-90px)'
            el.style.opacity = 0
        },
        enter: function (el, done) {
            gsap.to(el, {
                duration: 3,
                y: 0,
                opacity: 1,
                ease: 'bounce.out',
                onComplete: done, //tells on vue component that is done with this stage and go to the next stage
            })
        },
        afterEnter: function () {
            //   console.log('after enter'); //after 3 sec
        }
    },
    created() {
        this.loadEmployees();
    },
}

</script>

<style scoped lang="scss">

.team-portraits {
  height: auto;
  width: 100%;
  position: relative;
  color: white;
  background-color: white;
  overflow: hidden;
  padding: 1%;
  text-align: center;
  justify-content: center;

  .individual {
    display: inline-flex;

    img {
      height: 100px;
      width: auto;
    }
  }
}

.landing-section {
  display: block;
  max-height: 100vh;
  position: relative;
  width: 100%;
  background: linear-gradient(242deg, #2c4d58 21%, #000000 53%);
}

.landing-section_hero-diamond {
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-position: 50%;
}

.texAndLogo_content {
  padding-top: 45em;
}

.grid-container-home {
  width: 92%;
  margin: 0 auto;
  overflow: hidden;
  height: 100vh;
}

.textAndLogo__content-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: left;
  position: relative;
  left: 0;
}

.textAndLogo_block-text__title {
  font-size: 9em;
  background: rgb(47, 152, 200);
  background: linear-gradient(125deg, #d3e5ed 50%, #8caee7 72%, #e398d6 82%);
  text-decoration: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1em;
  font-weight: 900;
  text-align: right;
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

}

.textAndLogo_block-text__title,
.textAndLogo__bottom-text {
  font-family: "Heebo", sans-serif;
  z-index: 2;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.textAndLogo__bottom-text {
  font-size: 2.4em;
  background: rgb(47, 152, 200);
  background: linear-gradient(125deg, #d3e5ed 50%, #8caee7 72%, #e398d6 82%);
  font-weight: 900;
  text-align: right;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textAndLogo_block-text__title,
.textAndLogo__bottom-text {
  font-weight: 900;
  text-align: right;
}

.textAndLogo__content-right {
  float: right;
  width: calc(27% - 2px);
  zoom: 200%;
  position: relative;
  bottom: 108px;
  z-index: 3;
  overflow: visible;

  .floating-image {
    transform: translatey(0px);
    animation: float 4s ease-in-out infinite;
  }

  .static-image {
    position: absolute;
    left: 0;
    opacity: 0.5;
  }

}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

//#triangles-hero-compound {
//  position: absolute;
//  height: 100em;
//  width: calc(60% - 2px);
//  top: 3%;
//  left: 40%;
//}
//
//#triangles-hero-1 {
//  position: absolute;
//  height: 25%;
//  top: 0;
//  left: -1%;
//}
//
//#triangles-hero-duo {
//  position: absolute;
//  height: 35%;
//  top: -3%;
//  left: 26%;
//}
//
//#trianglesHero3 {
//  position: absolute;
//  height: 28%;
//  top: 100em;
//  left: 68%;
//  z-index: 2;
//}

@media screen and (max-width: 1990px) {
  .textAndLogo__content-right {
    width: calc(27% - 2px);
    zoom: 150%;
    position: relative;
    bottom: 200px;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 184px;
  }

  #triangles-hero-compound {
    position: absolute;
    display: block;
    height: 66em;
    left: 40%;
    top: 14%
  }
  #triangles-hero-1 {
    height: 25%;
  }
  #triangles-hero-duo {
    position: absolute;
    height: 40%;
    top: 0;
    left: 29%;
  }
  #trianglesHero3 {
    position: absolute;
    top: 73em;
    left: 71%;
  }
}

@media screen and (max-width: 1440px) {
  .textAndLogo__content-right {
    zoom: 130%;
    width: calc(27% - 2px);
    position: relative;
    bottom: 360px;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 275px;
  }
  #triangles-hero-compound {
    position: absolute;
    display: block;
    height: 55em;
    top: 16%;
    left: 40%;
  }

  #trianglesHero3 {
    position: absolute;
    height: 25%;
    top: 67em;
    left: 67%;
  }
  #triangles-hero-duo {
    position: absolute;
    height: 40%;
    top: 0;
    left: 24%;
  }
}

@media screen and (max-width: 1330px) {
  .textAndLogo__content-right {
    zoom: 130%;
    width: calc(25% - 2px);
    bottom: 371px;
  }
}

@media screen and (max-width: 1024px) {
  .landing-section_hero-diamond {
    background-position: 77%;
  }
  .textAndLogo_block-text__title {
    font-size: 6em;
  }
  .textAndLogo__bottom-text {
    font-size: 1.6em;
  }
  .textAndLogo__content-right {
    width: calc(31% - 2px);
    position: relative;
    bottom: 414px;
    zoom: 120%;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 371px;
  }
  #triangles-hero-compound {
    position: absolute;
    display: block;
    top: -4%;
    left: 37%;
    transform: scale(1.12);
  }
  #triangles-hero-1 {
    position: absolute;
    height: 15%;
  }

  #triangles-hero-duo {
    position: absolute;
    height: 29%;
    left: 15%;
  }

  #trianglesHero3 {
    position: absolute;
    height: 14em;
    top: 48em;
    left: 61%;;
  }

}

@media screen and (max-width: 768px) {

  .textAndLogo__content-right {
    width: calc(30% - 2px);
    position: relative;
    bottom: 611px;
    zoom: 95%;
  }
  .textAndLogo__content-left {
    left: 0;
    bottom: 220px;
  }
  #triangles-hero-compound {
    position: absolute;
    height: 26em;
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
    top: 45px;
    left: 39%;
  }
  #triangles-hero-1 {
    position: absolute;
    height: 15%;
  }

  #triangles-hero-duo {
    position: absolute;
    height: 25%;
    left: 19%;
    top: -20px;
  }

  #trianglesHero3 {
    position: absolute;
    height: 20%;
    top: 35em;
    left: 62%;
  }
  .textAndLogo_block-text {
    margin-top: 15em;
  }

  .textAndLogo_block-text__title {
    font-size: 5em;
  }

  .textAndLogo__bottom-text {
    font-size: 21px;
  }
}

@media screen and (max-width: 425px) {

  .landing-section_hero-diamond {
    -webkit-background-size: cover;
    background-position: center;
  }

  .texAndLogo_content {
    padding-top: 53em;
  }

  .textAndLogo__content-right {
    position: relative;
    zoom: 120%;
    bottom: 620px;

    .static-image {
      left: 50%;
      transform: translateX(-50%);
    }

  }

  .textAndLogo__content-left,
  .textAndLogo__content-right {
    width: 100%;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .textAndLogo__content-left {
    left: 0;
    bottom: 300px;
  }

  #triangles-hero-compound {
    -o-object-fit: cover;
    object-fit: cover;
    top: 32%;
    left: 8%;
    width: 140px;
    -webkit-transform: scale(4.5);
    transform: scale(4.5);
    height: 12em;
  }

  #triangles-hero-1 {
    display: none;
  }

  #triangles-hero-duo {
    display: none;
  }

  #trianglesHero3 {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  #triangles-hero-compound {
    left: -1%;
    width: 138px;
  }
}

@media screen and(max-width: 330px) {
  #triangles-hero-compound {
    top: 27%;
    left: -18%;
  }
  .texAndLogo_content {
    padding-top: 51em;
  }
  .textAndLogo_block-text__title {
    font-size: 4.5em;
  }
  .textAndLogo__bottom-text {
    font-size: 19px;
  }
}
</style>
